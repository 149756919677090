import { ButtonProps, LinkButtonSettings } from '@almbrand/button';
import { CtaGroupProps } from '@almbrand/ctagroup';

export const ctaGroupMargins = (ctaMarginTop: string, ctaMarginBottom: string): string => {
	const marginTop = ctaMarginTop !== 'none' ? `pt-${ctaMarginTop}` : '';
	const marginBottom = ctaMarginBottom !== 'none' ? `pb-${ctaMarginBottom}` : '';

	return `${marginTop} ${marginBottom}`.trim();
};

export const generateButtonArray = (buttons: Button[], ctaGroupMargins: string): CtaGroupProps => {
	if (!buttons || buttons.length === 0) {
		return null;
	}

	return {
		ctaGroup:
			buttons?.map((button) => {
				return {
					children: button?.title || '',
					buttonType: (button?.type === 'cta' ? button?.color : button?.type) as ButtonProps['buttonType'],
					linkButtonSettings: {
						href: button?.link?.url,
						target: button?.target as LinkButtonSettings['target'],
						title: button?.title,
					},
				};
			}) || [],
		className: ctaGroupMargins,
	};
};
