import $ from 'jquery'; // Import jQuery into your React component
import React, { useEffect } from 'react';

interface FormRendererProps {
	header?: string;
	backgroundColor?: {
		cssClass?: string;
		colorHex?: string;
		colorComplexion?: string;
	};
	paddingAll?: string;
	useReadline?: boolean;
	template?: string;
	allowAnonymousSubmission?: boolean;
	allowMultipleSubmission?: boolean;
	showNavigationBar?: boolean;
	contentType?: string;
	contentId?: number;
	assets?: {
		OriginalJquery?: string;
		Jquery?: string;
		Prerequisite?: string;
		Css?: string;
		ViewModeJs?: string;
	};
}

const FormRenderer: React.FC<FormRendererProps> = (props) => {
	const {
		header = '',
		backgroundColor = { cssClass: '' },
		paddingAll = '',
		useReadline = false,
		template = '',
		allowAnonymousSubmission = false,
		allowMultipleSubmission = false,
		showNavigationBar = false,
		contentType = '',
		contentId = 0,
		assets = {},
	} = props;

	useEffect(() => {
		// Log content for debugging purposes
		//console.log('FormRenderer props: ', props);
		if (!template) {
			console.error('FormRenderer: Template is missing', props);
			return;
		}

		// Step 1: Inject jQuery and Episerver form initialization scripts
		if (
			(window as any).epi == null ||
			(window as any).epi.EPiServer == null ||
			(window as any).epi.EPiServer.Forms == null
		) {
			// Safely evaluate the scripts from Episerver
			if (assets.OriginalJquery) {
				$.globalEval(assets.OriginalJquery);
			}
			if (assets.Jquery) {
				$.globalEval(assets.Jquery);
			}
		}

		// Step 2: Inject custom CSS for the form
		if (assets.Css) {
			const styleElement = document.createElement('style');
			styleElement.type = 'text/css';
			styleElement.innerHTML = assets.Css;
			document.head.appendChild(styleElement);
		}

		// Step 3: Attach the form template into the DOM and run the scripts
		const formContainer = document.getElementById(`form-container-${contentId}`);
		if (formContainer) {
			// Inject the form HTML into the container
			$(formContainer).html(template);

			// Run the prerequisite scripts and initialize the form
			if (assets.Prerequisite) {
				$.globalEval(assets.Prerequisite);
			}

			// Ensure the Episerver form scripts are initialized properly
			if (assets.ViewModeJs) {
				(window as any).epi.EPiServer.Forms.__Initialized = undefined;
				$.globalEval(assets.ViewModeJs);
			}
		}
	}, [template, assets, contentId]);

	// If template is missing, show an error
	if (!template) {
		return <div>Error: Template is missing</div>;
	}

	return (
		<div className={`Form__Container ${backgroundColor.cssClass || ''} padding-${paddingAll}`}>
			{showNavigationBar && <div className="Form__NavigationBar"></div>}
			<div id={`form-container-${contentId}`} className="Form__MainBody"></div> {/* Placeholder for the form */}
		</div>
	);
};

export default FormRenderer;
