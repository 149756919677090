import React from 'react';
import { mapDictionary, setPageMetaData } from 'services';
export class Page {
	private _pageModel: PageModel;
	private dictionary: Map<string, string>;

	public constructor(pageModel: PageModel) {
		this._pageModel = pageModel;
		setPageMetaData(this._pageModel);
		this.dictionary = mapDictionary(this._pageModel?.frontendConfiguration?.globalDictionary);
	}

	public get pageType(): string {
		return this._pageModel?.contentType;
	}

	public get footerContent(): FooterReference {
		return this._pageModel?.frontendConfiguration?.footerReference;
	}

	public get notificationBannerContent(): NotificationReference {
		return this._pageModel?.frontendConfiguration?.notificationReference;
	}

	public get globalDictionary(): Map<string, string> {
		return this.dictionary;
	}

	public get headerContent(): HeaderReference {
		return this._pageModel?.frontendConfiguration.headerReference;
	}

	public get ChatbotContent(): boolean {
		return this._pageModel?.hideAlbottaScript;
	}

	public get content(): MainArea {
		return this._pageModel?.mainArea;
	}

	public get theme(): string {
		return this._pageModel?.theme;
	}

	public get url(): CanonicalUrl {
		return this._pageModel?.canonicalUrl;
	}
}

export const PageContext = React.createContext<Partial<Page>>(new Page(null));
