import { TeaserCard as TeaserComponent, TeaserCardProps as TeaserComponentProps } from '@almbrand/teasercard';
import { ctaGroupMargins, generateButtonArray } from 'utilities';

export interface TeaserCardProps extends LocalId {
	backgroundColor?: BackgroundColor;
	contentId: number | string;
	textArea?: string;
	imgHeight: TeaserComponentProps['icon']['size'];
	image: Image;
	buttons?: Button[];
	ctaMarginTop?: string;
	ctaMarginBottom?: string;
	padding?: number | string;
	imgRatio?: string;
	paddingBottom?: number | string;
	paddingSides?: number | string;
	paddingTop?: number | string;
}

export const TeaserCard: React.FC<TeaserCardProps> = ({
	textArea,
	image,
	backgroundColor,
	contentId,
	buttons,
	ctaMarginTop,
	ctaMarginBottom,
	imgHeight,
	paddingBottom,
	paddingSides,
	paddingTop,
	useReadline,
	readlineId,
}) => {
	let uspTeaserCard = false;
	if (paddingBottom === 'none' && paddingTop === 'none' && paddingSides === 'none') {
		uspTeaserCard = true;
	}

	return (
		<TeaserComponent
			id={useReadline && readlineId ? readlineId : contentId}
			backgroundColor={backgroundColor?.cssClass}
			icon={{
				url: image?.url,
				altText: image?.properties?.altText,
				size: imgHeight,
			}}
			content={textArea}
			ctaGroupProps={generateButtonArray(buttons, ctaGroupMargins(ctaMarginTop, ctaMarginBottom))}
			usp={uspTeaserCard}
		/>
	);
};
