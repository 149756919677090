import { Button, ButtonProps, LinkButtonSettings } from '@almbrand/button';
import { CtaGroup } from '@almbrand/ctagroup';
import { RichText } from '@almbrand/richtext';
import classNames from 'classnames';
import { useState } from 'react';
import { ctaGroupMargins } from 'utilities';
import './ParagraphComponent.scss';

export interface ParagraphComponentProps extends LocalId {
	//	countdownPositionTop: boolean;
	//	header: string;
	richText: string;
	contentText?: string; // hidden text
	padding?: string;
	backgroundColor?: BackgroundColor; // TODO: map in component library
	buttons?: Button[];
	ctaMarginTop?: string;
	ctaMarginBottom?: string;
	contentId?: number | string;
	//	trackAsPromotion: boolean; // TODO: how can we use this one
}

export const ParagraphComponent: React.FC<ParagraphComponentProps> = ({
	contentId,
	backgroundColor,
	padding,
	richText,
	contentText,
	buttons,
	ctaMarginTop,
	ctaMarginBottom,
	useReadline,
	readlineId,
}) => {
	const [isContentTextVisible, setIsContentTextVisible] = useState(false);

	const toggleContentTextVisibility = () => {
		setIsContentTextVisible(!isContentTextVisible);
	};

	const mapButtonsToCtaGroup = (buttons: Button[]): ButtonProps[] =>
		buttons.map(({ title = '', type, color, link, target }) => ({
			children: title,
			buttonType: (type === 'cta' ? color : type) as ButtonProps['buttonType'],
			linkButtonSettings: {
				title,
				href: link?.url,
				target: target as LinkButtonSettings['target'],
			},
		}));

	return (
		<div
			className={classNames('paragraph', backgroundColor?.cssClass, `p-${padding}`)}
			style={{ '--blockBgColor': !isContentTextVisible && contentText ? '#f8f8f8' : '' } as React.CSSProperties} // TODO: should take backgroundColor.colorHex from cms
		>
			<div className={!isContentTextVisible && contentText && 'paragraph__add-fade'}>
				<RichText id={useReadline && readlineId ? readlineId : contentId} description={richText} />
			</div>
			{buttons && (
				<CtaGroup
					ctaGroup={mapButtonsToCtaGroup(buttons)}
					className={ctaGroupMargins(ctaMarginTop, ctaMarginBottom)}
				/>
			)}
			{isContentTextVisible && contentText && <RichText description={contentText} />}
			{contentText && (
				<div className="paragraph__contentVisible">
					<Button buttonType="link" onClick={toggleContentTextVisibility}>
						{isContentTextVisible ? 'Read Less' : 'Read More'}
					</Button>
				</div>
			)}
		</div>
	);
};
