import { ThemeSelector } from '@almbrand/themeselector';
import { useTrackingListener } from '@almbrand/trackinghooks';
import { PageType } from 'components/PageType';
import { Page as PageContent, PageContext } from 'context/PageContext';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { optimizelyContentApi } from 'services';
import useSWR from 'swr';
import './styles/globals.scss';

const App = () => {
	useTrackingListener();
	const location = useLocation();
	const { data, error, isLoading } = useSWR(location.pathname, optimizelyContentApi);

	const pageValue = useMemo(() => new PageContent(data), [data]);

	return (
		<ThemeSelector themeName="Codan-theme">
			{error && <div>Error fetching content: {error.message}</div>}
			{isLoading}
			{data && (
				<PageContext.Provider value={pageValue}>
					<PageType />
				</PageContext.Provider>
			)}
		</ThemeSelector>
	);
};

export default App;
