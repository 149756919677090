import { Accordion } from '@almbrand/accordion';
import { Button, ButtonProps } from '@almbrand/button';
import { RichText } from '@almbrand/richtext';
import { ctaGroupMargins, generateButtonArray } from 'utilities';

interface AccordionItem {
	question: string;
	answer: string;
	isNew: boolean;
	showLastUpdated: boolean;
	lastUpdatedText: string;
	contentType: string;
	contentId: number;
	buttons?: Button[];
	ctaMarginTop?: string;
	ctaMarginBottom?: string;
}

interface AccordionGroup {
	title?: string;
	contentType: string;
	contentId: number;
	accordionItems: {
		version: number;
		items: {
			content: AccordionItem;
		}[];
	};
}

interface AccordionComponentProps extends LocalId {
	isFaqAccordions?: boolean;
	header?: string;
	accordionsArea: {
		groups?: AccordionGroup[];
		items?: AccordionItem[];
	};
	style?: string;
	padding?: boolean;
	ctaBlock?: CTABlock;
	backgroundColor?: BackgroundColor;
	itemsBackgroundColor?: BackgroundColor;
	showUsabillaInPage?: boolean;
	contentId?: string | number;
}

export const AccordionComponent: React.FC<AccordionComponentProps> = ({
	// isFaqAccordions,
	// header,
	accordionsArea,
	ctaBlock,
	// style,
	// padding,
	// backgroundColor,
	// itemsBackgroundColor,
	// showUsabillaInPage,¨
	header,
	useReadline,
	readlineId,
	contentId,
}) => {
	const { groups, items } = accordionsArea;

	return (
		<>
			{header && (
				<div className={'AccordionComponent__header'}>
					<RichText description={header} />
				</div>
			)}
			{groups && groups.length > 0
				? groups.map((group, index) => (
						<div key={index} className="AccordionComponent__groupSpacing">
							<Accordion
								header={group.title}
								items={group.accordionItems.items.map((item, itemIndex) => {
									return {
										id: itemIndex,
										title: item.content.question,
										content: item.content.answer,
										isActive: false,
										ctaGroupProps: generateButtonArray(
											item.content.buttons,
											ctaGroupMargins(item.content.ctaMarginTop, item.content.ctaMarginBottom),
										),
									};
								})}
								id={useReadline && readlineId ? readlineId : contentId}
							/>
						</div>
					))
				: items.map((item, itemIndex) => {
						return (
							<Accordion
								items={[
									{
										id: itemIndex,
										title: item.question,
										content: item.answer,
										isActive: false,
										ctaGroupProps: generateButtonArray(
											item.buttons,
											ctaGroupMargins(item.ctaMarginTop, item.ctaMarginBottom),
										),
									},
								]}
								key={itemIndex}
								id={useReadline && readlineId ? readlineId : contentId}
							/>
						);
					})}

			{ctaBlock?.title && (
				<div className="pt-spacing-md">
					<Button
						buttonType={
							(ctaBlock.btnType === 'cta'
								? ctaBlock.btnColor
								: ctaBlock.btnType) as ButtonProps['buttonType']
						}
					>
						{ctaBlock.title}
					</Button>
				</div>
			)}
		</>
	);
};
