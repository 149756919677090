import { Bento, TaglineProps } from '@almbrand/bento';
import { ctaGroupMargins, generateButtonArray } from 'utilities';

export interface BentoComponentProps extends LocalId {
	content: string;
	contentId: number | string;
	buttons?: Button[];
	ctaMarginTop?: string;
	ctaMarginBottom?: string;
	backgroundColor?: BackgroundColor;
	tagline?: string;
	taglineColor?: string;
	height: 'standard' | 'large';
}

export const BentoComponent: React.FC<BentoComponentProps> = ({
	content,
	contentId,
	buttons,
	ctaMarginTop,
	ctaMarginBottom,
	backgroundColor,
	tagline,
	taglineColor,
	height,
}) => {
	return (
		<Bento
			id={contentId}
			content={content}
			backgroundColor={backgroundColor?.cssClass}
			tagline={{ title: tagline, color: taglineColor as TaglineProps['color'] }}
			height={height}
			ctaGroupProps={generateButtonArray(buttons, ctaGroupMargins(ctaMarginTop, ctaMarginBottom))}
		/>
	);
};
