import { PageContext } from 'context/PageContext';
import { useContext, useRef } from 'react';

import { Container } from '@almbrand/container';
import { Grid, GridCell } from '@almbrand/grid';
import classNames from 'classnames';
import { NotificationBanner } from 'components/2-molecules';
import { ContainerLayout, FooterComponent, HeaderComponent } from 'components/4-habitats';
import { useScrollDirection } from 'hooks/useScrollDirection';
import { useTrackPage } from 'hooks/useTrackPage';

export const ContentPage: React.FC<PageModel> = () => {
	const pageContext = useContext(PageContext);

	const { headerContent, content, footerContent, notificationBannerContent } = pageContext ?? {}; // TODO: add ChatbotContent when Header scroll issue is fixed

	const headerRef = useRef<HTMLDivElement>(null);
	const scrollDirection = useScrollDirection(headerRef, 50);

	useTrackPage();

	return (
		<>
			<Container
				desktopWidth="fullWidth"
				tabletWidth="fullWidth"
				mobileWidth="fullWidth"
				className={classNames('Header', scrollDirection == 'UP' ? 'Header_sticky' : 'Header_hidden')}
			>
				<Container desktopWidth="1600" tabletWidth="1280" mobileWidth="744">
					<Grid ref={headerRef}>
						<GridCell>
							<HeaderComponent {...headerContent} />
						</GridCell>
					</Grid>
				</Container>
			</Container>

			{notificationBannerContent && (
				<Container desktopWidth="1600" tabletWidth="1280" mobileWidth="744">
					<Grid container>
						<GridCell>
							<NotificationBanner {...notificationBannerContent} />
						</GridCell>
					</Grid>
				</Container>
			)}

			{content?.items?.map((component) => (
				<ContainerLayout component={component} key={component?.content?.contentId} />
			))}

			<Container noPadding desktopWidth="fullWidth" tabletWidth="fullWidth" mobileWidth="fullWidth">
				<FooterComponent {...footerContent} />
				{/* {!ChatbotContent && <ChatbotComponent />} */}
			</Container>
		</>
	);
};
