import { pushToDataLayer } from '@almbrand/trackinghooks';
import { useEffect } from 'react';

/**
 * Custom hook to track page views and time spent on the page.
 *
 * This hook pushes data to the data layer for tracking purposes. It tracks the page section,
 * brand, previous page URL, platform, and full URL. It also tracks page view events and
 * timestamps for time spent on the page.
 *
 * Usage:
 *
 * ```tsx
 * useTrackPage();
 * ```
 */
export const useTrackPage = () => {
	useEffect(() => {
		// Push initial page data to the data layer
		pushToDataLayer({
			pageSection: window.location.pathname.startsWith('/erhverv/') ? 'erhverv' : 'privat',
			brand: 'Codan',
			previousPageUrl: sessionStorage.getItem('previousPageUrl') || '',
			platform: 'web',
			fullUrl: window.location.href,
		});

		// Track pageview event
		pushToDataLayer({
			event: 'trackPageview',
		});

		// Initialize timestamp outside of interval
		let timeStamp = 0;

		// Set up an interval for tracking time spent on page
		const intervalId = setInterval(() => {
			timeStamp += 30;

			pushToDataLayer({
				event: 'trackTimestamp',
				timestampTracking: timeStamp,
			});
		}, 30000);

		// Clear the interval after 180 seconds
		const timeoutId = setTimeout(() => {
			clearInterval(intervalId);
		}, 180000);

		// Update sessionStorage with the current page URL
		sessionStorage.setItem('previousPageUrl', window.location.href);

		// Clear the timeout on component unmount to prevent memory leaks
		return () => {
			clearTimeout(timeoutId);
		};
	}, []);
};
