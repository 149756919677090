import { Header } from '@almbrand/header';
import { NavLink } from 'react-router-dom';

export const HeaderComponent: React.FC<HeaderReference> = ({
	logoImage,
	logoUrl,
	headerLinks,
	loginUrl,
	loginLabelText,
	loginIcon,
	//	menuMobilIcon,
	//	menuCloseMobilIcon,
	//	claimsUrl,
	//	claimsLabelText,
	//	searchLabelText,
	//	searchIcon,
	privateLabel,
	privatePageUrl,
	businessLabel,
	businessPageUrl,
}) => {
	const createSegmentLink = (label, pageUrl) => ({
		text: label ?? '',
		url: pageUrl?.url ?? '',
		isExternalLink: pageUrl?.isExternalLink ?? false,
		useVueRoute: pageUrl?.useVueRoute ?? false,
	});

	const mapHeaderLinks = (links) =>
		links
			.map((link) => ({
				text: link?.text ?? '',
				title: link?.title ?? '',
				url: link?.url ?? '',
				target: link?.target ?? '',
				isExternalLink: link?.isExternalLink ?? false,
				useVueRoute: link?.useVueRoute ?? false,
			}))
			.filter((link) => link.text && link.url);

	const segmentLinks = [
		privateLabel && createSegmentLink(privateLabel, privatePageUrl),
		businessLabel && createSegmentLink(businessLabel, businessPageUrl),
	].filter(Boolean);

	return (
		<Header
			logoUrl={logoImage?.url ?? ''}
			logoLink={logoUrl?.url ?? ''}
			segmentLinks={segmentLinks}
			navigationLinks={mapHeaderLinks(headerLinks)}
			buttonProps={{
				buttonType: 'personal',
				children: loginLabelText,
				size: 'small',
				linkButtonSettings: {
					href: loginUrl?.url,
				},
				icon: {
					src: loginIcon?.url,
					altText: loginIcon?.properties?.altText ?? 'Login Icon',
				},
			}}
			renderChildren={(link: Link): JSX.Element => {
				return (
					<NavLink key={link.url} to={link.url} title={link.title}>
						{link.text}
					</NavLink>
				);
			}}
		/>
	);
};
