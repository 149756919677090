import { GridCell } from '@almbrand/grid';
import classNames from 'classnames';
import { Component } from 'components/Component';

export const GridCellComponent: React.FC<{ component: ContentApiModel; applySpacings?: boolean }> = ({
	component,
	applySpacings,
}) => {
	const { gridSize, tabletGridSize, mobileGridSize, gridMove, mobileGridMove, tabletGridMove } =
		component?.grid ?? {};

	const getGridSize = (size?: GridCellWidth | 'None') => {
		return !size || size === 'None' ? '12col' : size;
	};

	const { marginBottom, marginTop, gridAlignment } = component?.style ?? {};

	return (
		<GridCell
			desktopWidth={gridSize}
			tabletWidth={getGridSize(tabletGridSize)}
			mobileWidth={getGridSize(mobileGridSize)}
			desktopOffset={gridMove}
			tabletOffset={tabletGridMove}
			mobileOffset={mobileGridMove}
			className={classNames(
				applySpacings && `pb-${marginBottom} pt-${marginTop}`,
				gridAlignment !== 'top' && `u-grid__cell--${gridAlignment}`,
			)}
		>
			<Component {...component?.content} />
		</GridCell>
	);
};
